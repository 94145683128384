import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import('@/views/adminHome'),
            redirect: '/leaveShow',
            children: [
                {
                    path: '/leaveShow',
                    meta: {name: '全部请假信息'},
                    component: () => import('@/views/leave/leaveShow')
                },
                {
                    path: '/leaveInsert',
                    meta: {name: '添加请假'},
                    component: () => import('@/views/leave/leaveInsert')
                },
                {
                    path: '/password',
                    name: 'Password',
                    meta: {name: '修改密码'},
                    component: () => import('@/views/Password.vue')
                }
            ]
        },
        {
            path: '/login', component: () => import('@/views/adminLogin')
        },
    ]
})

// 定义一个数组，专门用户存放所有需要权限访问的页面
const authUrls = ['/login']

router.beforeEach((to, from, next) => {
    next()
    return
    // // 看 to.path 是否在 authUrls 中出现过
    // if (authUrls.includes(to.path)) {
    //     // 非权限页面，直接放行
    //     next()
    //     return
    // }
    //
    // // 是权限页面，需要判断token
    // const token = store.getters.token
    // if (token) {
    //     next()
    // } else {
    //     next('/login')
    // }
})


// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
