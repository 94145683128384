import { getInfo, setInfo } from '@/utils/storage'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state () {
    return {
      // 个人权证相关
      userInfo: getInfo()
    }
  },
  mutations: {
    // 所有mutations的第一个参数，都是state
    setUserInfo (state, obj) {
      state.userInfo = obj
      setInfo(obj)
    },
  },
  actions: {
    logout ({ commit }) {
      commit('setUserInfo', { id: '', name: '', token: '', yanjs:'',defaultPassword:'' })
    }
  },
  getters: {
    token (state) {
      return state.userInfo.token
    },
    id (state) {
      return state.userInfo.id
    },
    name (state) {
      return state.userInfo.name
    },
    defaultPassword (state) {
      return state.userInfo.defaultPassword
    },
    yanjs (state) {
      return state.userInfo.yanjs
    }
  }
})
